"use strict";
"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var voice_recorder_1 = require("../utils/voice-recorder");
//test
var useVoiceRecorder = function () {
    var _a = (0, react_1.useState)(0), seconds = _a[0], setSeconds = _a[1];
    var _b = (0, react_1.useState)(voice_recorder_1.initialState), recorderState = _b[0], setRecorderState = _b[1];
    var initializeRecorder = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var mediaRecorder, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, voice_recorder_1.RecorderInitializer)(function () {
                            setRecorderState(function (prevState) { return (__assign(__assign({}, prevState), { mediaNotFound: true })); });
                        })];
                case 1:
                    mediaRecorder = _a.sent();
                    if (mediaRecorder) {
                        setRecorderState(__assign(__assign({}, voice_recorder_1.initialState), { mediaRecorder: mediaRecorder, initialized: true }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var startRecording = (0, react_1.useCallback)(function () {
        var mediaRecorder = recorderState.mediaRecorder;
        if (mediaRecorder && !recorderState.recording) {
            (0, voice_recorder_1.clearAudio)();
            mediaRecorder.start(10);
            setRecorderState(function (prevState) { return (__assign(__assign({}, prevState), { recording: true })); });
        }
    }, [recorderState]);
    var pauseRecording = (0, react_1.useCallback)(function () {
        var mediaRecorder = recorderState.mediaRecorder;
        if (mediaRecorder && recorderState.recording) {
            mediaRecorder.pause();
            setRecorderState(function (prevState) { return (__assign(__assign({}, prevState), { recording_paused: true })); });
        }
    }, [recorderState]);
    var resumeRecording = (0, react_1.useCallback)(function () {
        var mediaRecorder = recorderState.mediaRecorder;
        if (mediaRecorder && recorderState.recording_paused) {
            mediaRecorder.resume();
            setRecorderState(function (prevState) { return (__assign(__assign({}, prevState), { recording_paused: false })); });
        }
    }, [recorderState]);
    var stopRecording = (0, react_1.useCallback)(function () {
        var mediaRecorder = recorderState.mediaRecorder;
        if (mediaRecorder &&
            (recorderState.recording || recorderState.recording_paused)) {
            mediaRecorder.stop();
            var _a = (0, voice_recorder_1.saveAudio)(), audio_1 = _a.audio, audioBlob_1 = _a.audioBlob, completed_1 = _a.completed;
            setRecorderState(function (prevState) { return (__assign(__assign({}, prevState), { recording: false, completed: completed_1, audio: audio_1, audioBlob: audioBlob_1, audioTime: 0 })); });
        }
    }, [recorderState]);
    var clearRecorder = (0, react_1.useCallback)(function () {
        (0, voice_recorder_1.clearAudio)();
        setSeconds(0);
        setRecorderState(__assign(__assign({}, voice_recorder_1.initialState), { mediaRecorder: null }));
        initializeRecorder();
    }, [initializeRecorder]);
    var stopStream = (0, react_1.useCallback)(function () {
        var mediaRecorder = recorderState.mediaRecorder;
        if (mediaRecorder) {
            var tracks = mediaRecorder.stream.getTracks();
            tracks.forEach(function (track) { return track.stop(); });
        }
    }, [recorderState]);
    (0, react_1.useEffect)(function () {
        var interval = null;
        if (recorderState.recording &&
            !recorderState.recording_paused &&
            !recorderState.completed) {
            interval = setInterval(function () {
                setSeconds(function (prevSeconds) { return prevSeconds + 1; });
            }, 1000);
        }
        return function () {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [recorderState.recording, recorderState.recording_paused]);
    (0, react_1.useEffect)(function () {
        initializeRecorder();
    }, [initializeRecorder]);
    return {
        recorder: {
            state: recorderState,
            start: startRecording,
            pause: pauseRecording,
            resume: resumeRecording,
            stop: stopRecording,
            clear: clearRecorder,
            duration: (0, voice_recorder_1.secondsToTime)(seconds),
            stream: {
                stop: stopStream,
            },
        },
        initializeRecorder: initializeRecorder,
    };
};
exports.default = useVoiceRecorder;
