"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.saveAudio = exports.secondsToTime = exports.clearAudio = exports.RecorderInitializer = void 0;
//storage of recorded data and type
var chunks = [];
var audioType = "audio/*";
//this function is to initialize and start recording audio using the MediaRecorder API.
var RecorderInitializer = function (failureRecording) { return __awaiter(void 0, void 0, void 0, function () {
    var navigator, stream, mediaRecorder, successRecording, failedRecording;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                navigator = window.navigator;
                // get the getUserMedia from the navigator object
                navigator.getUserMedia =
                    navigator.getUserMedia ||
                        navigator.webkitGetUserMedia ||
                        navigator.mozGetUserMedia ||
                        navigator.msGetUserMedia;
                if (!navigator.mediaDevices) return [3 /*break*/, 2];
                return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ audio: true })];
            case 1:
                stream = _a.sent();
                mediaRecorder = new MediaRecorder(stream);
                if (mediaRecorder) {
                    mediaRecorder.ondataavailable = function (e) {
                        if (e.data && e.data.size > 0) {
                            chunks.push(e.data);
                        }
                    };
                    return [2 /*return*/, mediaRecorder];
                }
                return [3 /*break*/, 3];
            case 2:
                successRecording = function (stream) {
                    var mediaRecorder = new MediaRecorder(stream);
                    if (mediaRecorder) {
                        mediaRecorder.ondataavailable = function (e) {
                            if (e.data && e.data.size > 0) {
                                chunks.push(e.data);
                            }
                        };
                        return mediaRecorder;
                    }
                };
                failedRecording = function (error) {
                    failureRecording(error);
                    console.log("Media Devices will work only with SSL.....", error);
                };
                navigator.getUserMedia({ audio: true }, successRecording, failedRecording);
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.RecorderInitializer = RecorderInitializer;
//This function simply resets the "chunks" array to an empty array, clearing any previously recorded audio data
var clearAudio = function () {
    chunks = [];
};
exports.clearAudio = clearAudio;
//This function converts seconds into an object with hours, minutes, and seconds properties.
//For example, if you pass in 3661 seconds, it will return {h: 1, m: 1, s: 1}.
var secondsToTime = function (secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        h: hours,
        m: minutes,
        s: seconds,
    };
    return obj;
};
exports.secondsToTime = secondsToTime;
var saveAudio = function () {
    // convert saved chunks to blob
    var blob = new Blob(chunks, { type: audioType });
    // generate video or audio url from blob
    var audio = window.URL.createObjectURL(blob);
    //It returns an object with the audio URL, the audio blob, and a completed flag.
    return {
        audio: audio,
        audioBlob: blob,
        completed: true,
    };
};
exports.saveAudio = saveAudio;
exports.initialState = {
    recording: false,
    recording_paused: false,
    mediaNotFound: false,
    audio: "",
    audioBlob: null,
    completed: false,
    mediaRecorder: null,
    initialized: false,
    audioTime: 0,
};
