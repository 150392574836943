"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readableToFocus = exports.focusToReadable = void 0;
var graphql_1 = require("../graphql/generated/react/graphql");
exports.focusToReadable = {
    CANDIDATE_BEHAVIORIAL: "Behavioral",
    CANDIDATE_TECHNICAL: "Technical",
    CANDIDATE_SITUATIONAL: "Situational",
    CANDIDATE_BEHAVIOR: "Behavioral",
    CANDIDATE_TECHNICAL_SKILLS: "Technical",
    CANDIDATE_COMMUNICATION: "Communication",
    CANDIDATE_PROBLEM_SOLVING: "Problem Solving",
    null: "Other",
};
exports.readableToFocus = {
    Behavioral: graphql_1.QuestionFocus.CandidateBehaviorial,
    "Technical Skills": graphql_1.QuestionFocus.CandidateTechnical,
    Communication: graphql_1.QuestionFocus.CandidateSituational,
    "Problem Solving": graphql_1.QuestionFocus.CandidateTechnical,
    behavioral: graphql_1.QuestionFocus.CandidateBehaviorial,
    technical: graphql_1.QuestionFocus.CandidateTechnical,
    situational: graphql_1.QuestionFocus.CandidateSituational,
};
